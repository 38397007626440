<template>
  <div class="flex items-center justify-between py-3 rounded-lg">
    <div class="flex justify-between flex-1 sm:hidden">
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
        :disabled="currentPage === 1"
        :class="{ 'bg-gray-50': currentPage === 1 }"
        @click="handlePreviousPage"
      >
        {{ $t('back') }}
      </button>
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
        :disabled="currentPage === totalPages"
        :class="{ 'bg-gray-50': currentPage === totalPages }"
        @click="handleNextPage"
      >
        {{ $t('next') }}
      </button>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          {{ $t('pagination.showing') }}
          <span class="font-medium">{{ start }}</span>
          {{ $t('pagination.to') }}
          <span class="font-medium">{{ end }}</span>
          {{ $t('pagination.of') }}
          <span class="font-medium">{{ totalCount }}</span>
          {{ $t('pagination.results') }}
        </p>
      </div>
      <div>
        <div class="inline-flex -space-x-px rounded-md shadow-sm isolate">
          <button
            class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            type="button"
            :disabled="currentPage === 1"
            :class="{ 'bg-gray-50': currentPage === 1 }"
            @click="handlePreviousPage"
          >
            <span class="sr-only">{{ $t('back') }}</span>
            <ChevronRightIcon class="w-4 h-4 rotate-180" aria-hidden="true" />
          </button>

          <button
            v-for="page in displayedPages"
            :key="page.name"
            type="button"
            :disabled="page === currentPage || isNaN(page)"
            :class="{ 'bg-gray-50': page === currentPage }"
            class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            @click="updatePage(page)"
          >
            {{ page }}
          </button>

          <button
            class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            type="button"
            :disabled="currentPage === totalPages"
            :class="{ 'bg-gray-50': currentPage === totalPages }"
            @click="handleNextPage"
          >
            <span class="sr-only">{{ $t('next') }}</span>
            <ChevronRightIcon class="w-4 h-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'

const props = defineProps({
  totalCount: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['update:currentPage'])

const totalPages = computed(() => {
  return Math.ceil(props.totalCount / props.pageSize)
})

const start = computed(() => {
  if (props.totalCount === 0) return 0
  return (props.currentPage - 1) * props.pageSize + 1
})

const end = computed(() => {
  return Math.min(props.currentPage * props.pageSize, props.totalCount)
})

const generatePageNumbers = (currentPage, totalPages, maxPages) => {
  if (totalPages <= maxPages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const pages = []
  const half = Math.floor(maxPages / 2)
  const start = Math.max(1, currentPage - half)
  const end = Math.min(totalPages, currentPage + half)

  if (start > 1) pages.push(1)
  if (start > 2) pages.push('...')

  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  if (end < totalPages - 1) pages.push('...')
  if (end < totalPages) pages.push(totalPages)
  return pages
}

const displayedPages = computed(() =>
  generatePageNumbers(props.currentPage, totalPages.value, 4)
)

const handlePreviousPage = () => {
  if (props.currentPage > 1) {
    emit('update:currentPage', props.currentPage - 1)
  }
}

const handleNextPage = () => {
  if (props.currentPage < totalPages.value) {
    emit('update:currentPage', props.currentPage + 1)
  }
}

const updatePage = (page) => {
  emit('update:currentPage', page)
}
</script>
